/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.shadow-ylw{
  box-shadow: 0 0.5rem 1rem rgba(255, 193, 7,17) !important;
}

.max50height{
  max-height: 30vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}